(function (module) {

    var rrProgramReviewerEditCtrl = function ($scope, $uibModalInstance, readinessReview, selectedProgram, selectedCommissionId, alertSvc, codeSvc, helperSvc, readinessSvc, eventSvc, personSvc, commissionAbbreviations) {
        var model = $scope;

        model.title = 'Assign Reviewer';
        model.readinessReview = angular.copy(readinessReview);
        model.selectedProgram = angular.copy(selectedProgram);
        model.selectedCommissionId = selectedCommissionId;
        model.selectedCommissionName = commissionAbbreviations[selectedCommissionId];
        model.currentReviewerIndex = -1;
        model.currentReviewer = null;
        model.selectedReviewer = {
            commissionId: model.selectedCommissionId,
            personId: null
        };
        model.currentReviewers = [];

        // Initialize selected reviewer if previously assigned for this program, commission
        if (model.selectedProgram.readinessReview && model.selectedProgram.readinessReview.reviewers) {
            model.currentReviewers = model.selectedProgram.readinessReview.reviewers.filter(function (reviewer) {
                return reviewer.commissionId === model.selectedCommissionId;
            });
            console.log("Current Reviewers:", model.currentReviewers);
            model.currentReviewerIndex = model.selectedProgram.readinessReview.reviewers.findIndex(function (reviewer) {
                return reviewer.commissionId === model.selectedCommissionId;
            });
            if (model.currentReviewerIndex > -1) {
                model.currentReviewer = model.selectedProgram.readinessReview.reviewers[model.currentReviewerIndex];
                model.selectedReviewer.personId = model.currentReviewer.personId;
            }
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };
          
        model.onSelectFxn = function (event) {
            console.log("Selected Person:", event);
            const selectedPerson = event.data;

            var updatedCurrentReviewers = model.currentReviewers;

            if (selectedPerson) {
                var existingReviewer = model.currentReviewers.find(function (reviewer) {
                    return reviewer.personId === selectedPerson.personId;
                });

                if (!existingReviewer && model.currentReviewers.length < 2) {

                    updatedCurrentReviewers.push({ commissionId: model.selectedCommissionId, firstName: selectedPerson.firstName, lastName: selectedPerson.lastName, middleName: selectedPerson.middleName, personId: selectedPerson.personId });
                }
            }
            model.currentReviewers = updatedCurrentReviewers;
        }

        model.removeReviewer = function (reviewerPersonId, commissionId) {
            console.log("Deleting reviewer with following info: ", reviewerPersonId, commissionId);
            var updatedCurrentReviewers = model.currentReviewers.filter(function (reviewer) {
                return reviewer.personId !== reviewerPersonId && reviewer.commissionId === commissionId;
            });

            model.currentReviewers = updatedCurrentReviewers;
            console.log("Updated Reviewers:", updatedCurrentReviewers);
        }

        model.save = function () {
            var updatedReadiness = angular.copy(readinessReview);
            var programIndex = readinessReview.programs.findIndex(function (record) {
                return record.programName === selectedProgram.programName && record.degreeCode === selectedProgram.degreeCode;
            });

            // Update reviewers list by clearing all reviewers of current commission and adding the ones selected in 
            const updatedReviewers = model.selectedProgram.readinessReview.reviewers.filter(function (reviewer) {
                return reviewer.commissionId !== model.selectedCommissionId;
            });

            model.currentReviewers.forEach(function (reviewer) {
                updatedReviewers.push(reviewer);
            });

            model.selectedProgram.readinessReview.reviewers = updatedReviewers;

            updatedReadiness.programs[programIndex] = model.selectedProgram;

            readinessSvc.assignReviewer(updatedReadiness).then(function (data) {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Reviewer successfully updated.");
                eventSvc.broadcast('refreshReadinessData');
            });

        /**
            personSvc.getPersonSummary(model.selectedReviewer.personId).then(function (person) {
                model.selectedReviewer.firstName = person[0].firstName;
                model.selectedReviewer.middleName = person[0].middleName;
                model.selectedReviewer.lastName = person[0].lastName;

                if (model.currentReviewerIndex > -1) {
                    model.selectedProgram.readinessReview.reviewers[model.currentReviewerIndex] = model.selectedReviewer;
                } else {
                    if (!model.selectedProgram.readinessReview.reviewers) model.selectedProgram.readinessReview.reviewers = [];
                    model.selectedProgram.readinessReview.reviewers.push(model.selectedReviewer);
                }

                updatedReadiness.programs[programIndex] = model.selectedProgram;
               
                readinessSvc.assignReviewer(updatedReadiness).then(function (data) {
                    
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Reviewer successfully updated.");
                    eventSvc.broadcast('refreshReadinessData');
                });
            });
        */
        };
    };

    module.controller('rrProgramReviewerEditCtrl', rrProgramReviewerEditCtrl);

}(angular.module('readinessReview')));